<template>
    <div class="mb-0">
      <card class="mb-0">
        <div class="row ma-0 pa-0">
            <v-col cols="12" class="pb-0">
                <span class="title">Do you really want to delete {{ titleName }} ?</span>
            </v-col>
            <v-col cols="12" class="pb-0">
                This step is not reversable.
            </v-col>
        </div>
        <v-row class="pb-5 px-3">
            <v-col cols="auto" class="d-flex ml-auto pa-0 pt-2">
        <v-btn
            class="body-2 font-weight-bold mr-5 cancel-color"
            @click="cancel()"
        >
            Cancel
        </v-btn>
        <v-btn
            class="body-2 font-weight-bold delete-color"
            :loading="editloading"
            @click="deleteRow()"
        >
            Delete
        </v-btn>
    </v-col>
    </v-row>
      </card>
    </div>
  </template>
  <script>
  import commonService from '@/services/commonFunction.js'
  export default {
    name:'delete',
    props: {
      title: {
        type: String,
        default: () => {
          return '';
        }
      },
      titleName: {
        type: String,
        default: () => {
          return '';
        }
      },
      cancel:{
        type: Function,
        default : () => null
      },
      deleteMethod:{
        type: Function,
        default : () => null
      },
      refreshData:{
        type: Function,
        default : () => null
      },
      id:{
        type : Number,
        default : () => 0
      }
    },
    data(){
      return {
        editloading:false,
      }
    },
    methods:{
        async deleteRow(){
            this.editloading = true
            try{
                await this.deleteMethod(this.id)      
                commonService.alert(this.title+ ' deleted successfully', 'Success')
                this.editloading = false
                this.refreshData();
                this.cancel();
            }
            catch(err){
                console.log(err);
                this.editloading = false
            }
        
      },
     
  
    }
  };
  </script>
  <style scoped>
    .title{
        font-size: 18px;
        font-weight: 500;
        padding-bottom: 0px;
    }
    .cancel-color{
        background-color: grey !important;
    }
    .delete-color{
        background-color: red !important;
    }
  </style>
  