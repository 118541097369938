<template>
    <div class="d-flex align-center">
      <v-menu
        v-if="hasMenuProps && menuItems.menu && (menuItems.menu.visibilityCondition ? menuItems.menu.visibilityCondition(item) : true)"
        :attach="hasMenuProps && (menuItems.menu.enableAttach ? menuItems.menu.enableAttach(item.id) : true)"
        dense
        offset-y
        left
        content-class="action-menu"
        :top="hasMenuProps && (menuItems.menu.enableTop ? menuItems.menu.enableTop(item.id) : true)"
        :element-id="item.id"
        :element-type="hasMenuProps && (menuItems.menu.elementType ? menuItems.menu.elementType : 'tablemenu') "
      >
        <template v-slot:activator="{ on }">
          <v-btn
            color="lightgrey"
            icon
            @click.stop
            v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list
          v-if="hasMenuProps && menuItems.menu.list"
          dense
          class="no-hyperlink"
        >
          <span
            v-for="subMenu in menuItems.menu.list"
            :key="subMenu.id"
          >
            <v-tooltip top :disabled="subMenu.toolTipCondition ? subMenu.toolTipCondition(item) : true">
              <template v-slot:activator="{ on }">
                <!-- if type is button -->
                <v-list-item
                  v-if="subMenu.type === 'button' && (subMenu.visibilityCondition ? subMenu.visibilityCondition(item) : true)"
                  @click.native="subMenu.buttonClick ? subMenu.buttonClick(item) : null"
                  class="menu-option"
                  v-on="on"
                  :disabled="subMenu.disabled ? subMenu.disabled(item) : false"
              >
                  <v-icon class="mr-2 title">
                    {{ subMenu.icon }}
                  </v-icon><v-list-item-title class="body-2 menu-title">
                    {{ subMenu.value }}
                  </v-list-item-title>
                </v-list-item>
            
                <!-- if type is routerLink -->
               
                <v-list-item
                  v-else-if="subMenu.type === 'routerLink' && (subMenu.visibilityCondition ? subMenu.visibilityCondition(item) : true)"
                  :to="subMenu.to(item,getValue(subMenu))"
                  @click.native="subMenu.buttonClick ? subMenu.buttonClick(item) : null"
                  class="menu-option"
                  v-on="on"
                  :disabled="subMenu.disabled ? subMenu.disabled(item) : false"
                >
                  <v-icon class="mr-2 title">
                    {{ subMenu.icon }}
                  </v-icon><v-list-item-title class="body-2 menu-title">
                    {{ subMenu.value }}
                  </v-list-item-title>
                </v-list-item>
  
                <!-- if type is iframe -->
                <template v-else-if="subMenu.type === 'iframe' && (subMenu.visibilityCondition ? subMenu.visibilityCondition(item) : true)">
                  <iframe
                    src="url"
                    :name="subMenu.iframe.name"
                    :style="subMenu.iframe.style"
                  />
                  <a
                    :href="subMenu.iframe.targetUrl(item)"
                    :target="subMenu.iframe.name"
                    class="text-decoration-none"
                  >
                    <v-list-item
                      @click.native="subMenu.buttonClick ? subMenu.buttonClick(item) : null"
                      v-on="on"
                    >
                      <v-icon class="mr-2 title">
                        {{ subMenu.icon }}
                      </v-icon><v-list-item-title class="body-2 menu-title">
                        {{ subMenu.value }}
                      </v-list-item-title>
                    </v-list-item>
                  </a>
                </template>
              </template>
              <span> {{subMenu.toolTipText}} </span>
            </v-tooltip>
          </span>
        </v-list>
      </v-menu>
      <span
        v-for="menu in menuItems.withoutMenu"
        :key="menu.id"
        class="menu-width"
      >
        <v-tooltip top :disabled="menu.toolTipCondition ? menu.toolTipCondition(item) : false">
          <template v-slot:activator="{ on }">
            <router-link
              v-if=" menu.type === 'routerLink' && (menu.visibilityCondition ? menu.visibilityCondition(item) : true)"
              :to="menu.to(item, getValue(menu))"
              @click.native="menu.buttonClick ? menu.buttonClick(item) : null"
              v-on="on"
            >
              <v-btn
                icon
                small
              >
                <v-icon small>
                  {{ menu.icon }}
                </v-icon>
              </v-btn>
            </router-link>
            <v-btn
              icon
              v-on="on"
              @click.native="menu.buttonClick ? menu.buttonClick(item) : null"
              v-if=" menu.type === 'button' && (menu.visibilityCondition ? menu.visibilityCondition(item) : true)"
            >
              <v-icon>
                {{ menu.icon }}
              </v-icon>
            </v-btn>
          </template>
          <span> {{menu.toolTipText}} </span>
        </v-tooltip>  
      </span>
    </div>
  </template>
  <script>
    export default {
      props: {
        menuItems: {
          type: Object,
          default () {
            return {}
          },
        },
        item: {
          type: Object,
          default () {
            return {}
          },
        },
      },
      computed: {
        hasMenuProps(){
          return this.menuItems && this.menuItems.menu
        }
      },
      methods:{
        getValue(menu){
          if(menu.secondParamOfTo){
            if(typeof(menu.secondParamOfTo)==='function')
              return menu.secondParamOfTo(this.item);
            else
              return menu.secondParamOfTo; 
          }
          return null;
        }
      }
    }
  </script>
  <style lang="scss" scoped>
    .menu-title{
      text-align: initial;
      font-weight: 400 !important;
    }
    .action-menu{
        max-width: 80%;
    }
    .menu-option{
      cursor: pointer;
    }
    .menu-option:hover{
      background: rgb(246 246 246);
    }
    .menu-width{
      width: 100%;
      text-align: right;
    }
  </style>
  